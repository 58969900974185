<template>
  <PatientInfoLayout>
    <b-container fluid>
      <div
        class="
          tw-top-0 tw-py-5
          lg:tw-flex
          tw-justify-between tw-items-center tw-flex-none tw-px-4
        "
      >
        <div class="tw-flex-initial tw-flex tw-items-center tw-text-warning">
          <span
            class="back-arrow tw-mr-3 tw-cursor-pointer"
            @click="cancel"
          ></span>
          <span class="tw-text-warning" v-if="previousRoute">
            {{ previousRoute.meta.title }}
          </span>
        </div>
        <div class="lg:tw-pr-6">
          <b-button
            size="sm"
            class="tw-text-primary tw-underline"
            variant="link"
            pill
            @click="$router.push(`/patients/${patientId}/intake`)"
          >
            View Intake History
          </b-button>
        </div>
      </div>
      <b-row
        align-h="center"
        align-v="center"
        style="padding-top: 20px; padding-bottom: 20px"
        class="tw-px-4"
      >
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <h4 class="mr-4 text-primary">Patient Information</h4>
              <b-card
                class="prescription-card"
                no-body
                v-if="!patientInfo.patientno"
              >
                <b-row align-v="center">
                  <b-col cols="12" md="9">
                    <b-row align-v="center">
                      <b-col cols="auto" class="mb-3">
                        <b-skeleton
                          animation="fade"
                          type="avatar"
                          size="72px"
                        ></b-skeleton>
                      </b-col>
                      <b-col>
                        <b-skeleton animation="fade" width="45%"></b-skeleton>
                        <b-skeleton animation="fade" width="55%"></b-skeleton>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" md="3" class="text-right">
                    <b-skeleton animation="fade" width="85%"></b-skeleton>
                    <b-skeleton animation="fade" width="65%"></b-skeleton>
                  </b-col>
                </b-row>
              </b-card>
              <b-card class="prescription-card" no-body v-else>
                <b-row>
                  <b-col cols="12">
                    <b-row align-v="center">
                      <b-col cols="12" lg="9">
                        <b-row align-v="center">
                          <b-col cols="12" lg="2" class="mb-3">
                            <b-avatar
                              size="72px"
                              :src="patientInfo.profileUrl"
                            ></b-avatar>
                          </b-col>
                          <b-col cols="12" lg="10">
                            <h3 class="mb-1 bold">
                              {{
                                `${patientInfo.firstname} ${patientInfo.lastname}`
                              }}
                              <small
                                v-if="patientInfo.transferredfromdoctorname"
                              >
                                (Transferred from Dr.
                                {{ patientInfo.transferredfromdoctorname }})
                              </small>
                            </h3>
                            <template v-if="patientInfo.desiredplan">
                              <b-row class="py-1">
                                <b-col cols="12" lg="4">
                                  Requested Medication:
                                </b-col>
                                <b-col class="text-black">
                                  <span
                                    v-for="(plan, i) in patientInfo.desiredplan"
                                    :key="i"
                                  >
                                    <!-- {{ plan.name +  (i === patientInfo.desiredplan.length -1 ? "": ',') || '' }} -->
                                    {{
                                      plan.name +
                                      (plan.pricingdetail != undefined
                                        ? " - " + plan.pricingdetail.nickname
                                        : (i ===
                                          patientInfo.desiredplan.length - 1
                                            ? ""
                                            : ",") || "")
                                    }}

                                    <br
                                      v-if="plan.pricingdetail != undefined"
                                    />

                                    <br />
                                  </span>
                                </b-col>
                              </b-row>
                            </template>
                            <template
                              v-else-if="patientInfo.preferredtreatment"
                            >
                              <b-row class="py-1">
                                <b-col cols="12" lg="4">
                                  Requested Medication:
                                </b-col>
                                <b-col class="text-black">
                                  {{ patientInfo.preferredtreatment || "" }}
                                </b-col>
                              </b-row>
                            </template>
                            <template v-else>
                              <b-row class="py-1">
                                <b-col cols="12" lg="4">
                                  Requested Medication:
                                </b-col>
                                <b-col class="text-black">
                                  Let doctor choose medication
                                </b-col>
                              </b-row>
                            </template>
                            <template v-if="patientInfo.preferredshipmentname">
                              <b-row class="py-1">
                                <b-col cols="12" lg="4">
                                  Preferred Shipment:
                                </b-col>
                                <b-col class="text-black">
                                  <span>
                                    {{
                                      patientInfo.preferredshipmentname || ""
                                    }}
                                  </span>
                                </b-col>
                              </b-row>
                            </template>

                            <template
                              v-if="
                                patientInfo.unselectedproduct != undefined &&
                                patientInfo.unselectedproduct.length > 0
                              "
                            >
                              <b-row class="py-1">
                                <b-col cols="12" lg="4">
                                  Declined onboarding treatment:
                                </b-col>
                                <b-col class="text-black">
                                  <span
                                    v-for="(
                                      plan, i
                                    ) in patientInfo.unselectedproduct"
                                    :key="i"
                                  >
                                    {{
                                      plan.name +
                                        (i ===
                                        patientInfo.unselectedproduct.length - 1
                                          ? ""
                                          : ",") || ""
                                    }}
                                  </span>
                                </b-col>
                              </b-row>
                            </template>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col cols="12" lg="3" class="text-right">
                        <h3 class="mb-0 bold">
                          {{ patientInfo.patientno }}
                        </h3>
                        <a href="#" class="text-underline" v-b-modal.intake>
                          View Intake History
                        </a>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>

          <b-row align-h="center" v-if="prescriptions.length > 0 && isRefill">
            <b-col cols="12" md="10">
              <h4 class="mr-4 text-primary">Follow-up</h4>
              <b-card class="followup-card" style="position: relative">
                <b-row v-if="patientInfo.followupdate">
                  <b-col cols="12" md="3" class="tw-mb-4 lg:tw-mb-0">
                    <span class="mb-1 text-primary">
                      <small>Current Follow up Date</small>
                    </span>
                    <p class="mb-0 text-default">
                      {{
                        parseDate(patientInfo.followupdatestamp, "MMMM d, yyyy")
                      }}
                    </p>
                  </b-col>
                  <b-col cols="12" md="3">
                    <span class="mb-1 text-primary">
                      <small>Months Until Follow Up</small>
                    </span>
                    <p class="mb-0 text-default">
                      {{ monthsUntilFollowUp }}
                      {{ pluralize(monthsUntilFollowUp, "Month") }}
                    </p>
                  </b-col>
                </b-row>
                <b-row class="mb-4" v-else>
                  <b-col cols="12" md="6">
                    <div role="group">
                      <label for="followup" class="text-primary">
                        <small>Schedule Follow-up</small>
                      </label>
                      <v-select
                        id="followup"
                        append-to-body
                        :calculate-position="withPopper"
                        v-model="selectedFollowUpOption"
                        label="text"
                        :options="followUpOptions"
                        :reduce="(followUpOptions) => followUpOptions.value"
                      >
                        <template #open-indicator="{ attributes }">
                          <b-icon
                            v-bind="attributes"
                            icon="chevron-down"
                            variant="primary"
                          ></b-icon>
                        </template>
                      </v-select>
                    </div>
                  </b-col>
                  <b-col cols="12" md="6">
                    <label for="followupdate" class="text-primary">
                      <small>Follow-up Date</small>
                    </label>
                    <b-form-datepicker
                      id="followupdate"
                      :disabled="selectedFollowUpOption !== 'Manual'"
                      :date-disabled-fn="dateDisabled"
                      v-model="followupDate"
                    ></b-form-datepicker>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>

          <b-row align-h="center" v-if="prescriptions.length > 0">
            <b-col cols="12">
              <h4 class="mr-4 text-primary">Prescription Order</h4>
              <b-alert :show="alert.show" :variant="alert.type">{{
                alert.message
              }}</b-alert>
              <b-card class="prescription-details" style="position: relative">
                <b-row class="mb-4">
                  <b-col cols="12" md="6">
                    <label for="subscription_startdate" class="text-primary">
                      <small>Subscription Start Date</small>
                    </label>
                    <b-form-datepicker
                      id="subscription_startdate"
                      :date-disabled-fn="subscriptionStartDateDisabled"
                      v-model="subscriptionstartdate"
                    ></b-form-datepicker>
                  </b-col>
                </b-row>
              </b-card>
              <b-card class="update-order">
                <div
                  class="
                    tw-grid tw-grid-cols-10 tw-mb-4 tw-space-y-4
                    lg:tw-space-y-0
                    md:tw-space-x-4
                    tw-place-items-stretch
                  "
                >
                  <div
                    role="group"
                    class="
                      tw-justify-between tw-flex tw-flex-col tw-col-span-10
                      md:tw-col-span-4
                    "
                  >
                    <label for="prescription" class="text-primary">
                      <small>Prescription</small>
                    </label>
                    <v-select
                      class="prescription-select"
                      id="prescription"
                      append-to-body
                      :calculate-position="withPopper"
                      v-model="prescription.productid"
                      label="text"
                      :options="prescriptions"
                      :reduce="(prescriptions) => prescriptions.value"
                      @input="selectedPrescription(prescription)"
                      :disabled="
                        !prescription.productid || isUpdateRefill === true
                      "
                      placeholder="Fetching data..."
                    >
                      <template #open-indicator="{ attributes }">
                        <b-icon
                          v-bind="attributes"
                          icon="chevron-down"
                          variant="primary"
                        ></b-icon>
                      </template>
                    </v-select>
                  </div>
                  <div
                    role="group"
                    class="
                      tw-justify-between tw-flex tw-flex-col tw-col-span-10
                      md:tw-col-span-2
                    "
                  >
                    <label for="dosage" class="text-primary">
                      <small>Dosage</small>
                    </label>
                    <v-select
                      class="prescription-select"
                      id="dosage"
                      append-to-body
                      :calculate-position="withPopper"
                      v-model="prescription.dosage"
                      label="text"
                      :options="
                        dosages[productid] &&
                        dosages[productid].filter(({ value }) => value !== '')
                      "
                      :reduce="(dosages) => dosages.value"
                      :disabled="!prescription.productid"
                      @input="changeDosage(prescription)"
                    >
                      <template #open-indicator="{ attributes }">
                        <b-icon
                          v-bind="attributes"
                          icon="chevron-down"
                          variant="primary"
                        ></b-icon>
                      </template>
                    </v-select>
                  </div>
                  <div
                    role="group"
                    class="
                      tw-justify-between tw-flex tw-flex-col tw-col-span-10
                      md:tw-col-span-3
                    "
                  >
                    <label for="unit" class="text-primary">
                      <small>Unit</small>
                    </label>
                    <v-select
                      class="prescription-select"
                      id="unit"
                      append-to-body
                      :calculate-position="withPopper"
                      v-model="prescription.pricingid"
                      label="text"
                      :options="
                        units[productid] &&
                        units[productid].filter(
                          (unit) => unit.dosage === prescription.dosage
                        )
                      "
                      :reduce="(units) => units.value"
                      clearable
                      @input="selectedUnit(productid, prescription.pricingid)"
                      :disabled="!prescription.dosage"
                    >
                      <template #open-indicator="{ attributes }">
                        <b-icon
                          v-bind="attributes"
                          icon="chevron-down"
                          variant="primary"
                        ></b-icon>
                      </template>
                    </v-select>
                  </div>
                  <div
                    role="group"
                    class="
                      tw-justify-between tw-flex tw-flex-col tw-col-span-10
                      md:tw-col-span-1
                    "
                  >
                    <label for="unit" class="text-primary">
                      <small>Refill</small>
                    </label>
                    <b-form-input
                      v-model.number="prescription.refillinput"
                      id="refill"
                      type="number"
                    ></b-form-input>
                  </div>
                </div>

                <b-row>
                  <!-- <b-col cols="12" md="6">
                  <b-form-group label="Start Date">
                    <b-input-group class="mb-2">
                      <flat-pickr
                        :config="config"
                        class="form-control pl-0"
                        v-model="prescription.startdate"
                        placeholder="MM/DD/YYYY"
                        id="birthday"
                      ></flat-pickr>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <div role="group">
                    <label for="test2">Frequency</label>
                    <v-select
                      id="frequency"
                      append-to-body 
                      :calculate-position="withPopper" 
                      v-model="prescription.frequency"
                      :options="frequency"
                    >
                      <template #open-indicator="{ attributes }">
                        <b-icon v-bind="attributes" icon="chevron-down" variant="primary"></b-icon>
                      </template>
                    </v-select>
                  </div>
                </b-col> -->
                  <b-col cols="12">
                    <div role="group">
                      <label for="textarea" class="text-primary">
                        <small>Doctor Notes</small>
                      </label>
                      <b-form-textarea
                        id="textarea"
                        v-model="prescription.notes"
                        class="pl-0"
                        rows="3"
                        max-rows="6"
                        style="overflow: auto"
                      ></b-form-textarea>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
              <b-row class="tw-space-y-4 lg:tw-space-y-0">
                <b-col cols="12" md="6"> </b-col>
                <b-col cols="12" md="3">
                  <b-button
                    pill
                    variant="outline-primary"
                    block
                    :disabled="processing"
                    v-on:click="cancel"
                    >Cancel</b-button
                  >
                </b-col>
                <b-col cols="12" md="3">
                  <b-button
                    v-if="isRefill === true"
                    pill
                    variant="primary"
                    block
                    v-on:click.prevent="addPrescription"
                    :disabled="processing"
                    >{{ processing ? "Processing" : "Save" }}</b-button
                  >
                  <b-button
                    v-if="isRefill === false"
                    pill
                    variant="primary"
                    block
                    v-on:click.prevent="updatePrescription"
                    :disabled="processing"
                    >{{ processing ? "Processing" : "Save" }}</b-button
                  >
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col cols="12">
              <b-card style="background-color: rgba(247, 247, 247, 0.5)">
                <b-row class="mb-4">
                  <b-col cols="12" md="6">
                    <b-skeleton animation="fade" type="input"></b-skeleton>
                  </b-col>
                  <b-col cols="12" md="3">
                    <b-skeleton animation="fade" type="input"></b-skeleton>
                  </b-col>
                  <b-col cols="12" md="3">
                    <b-skeleton animation="fade" type="input"></b-skeleton>
                  </b-col>
                </b-row>
                <b-row class="mb-4">
                  <b-col cols="12">
                    <b-skeleton
                      animation="fade"
                      type="input"
                      height="150px"
                    ></b-skeleton>
                  </b-col>
                </b-row>
              </b-card>
              <b-row>
                <b-col cols="4" md="6">
                  <b-skeleton type="button" width="150px"></b-skeleton>
                </b-col>
                <b-col cols="4" md="3">
                  <b-skeleton type="button" width="100%"></b-skeleton>
                </b-col>
                <b-col cols="4" md="3">
                  <b-skeleton type="button" width="100%"></b-skeleton>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

    <ModalInAddition
      :prescriptions="inAdditionPrescriptions"
      :processing="processing"
      @in-addition="updatePrescription"
      @modal-close="hasDuplicate = false"
    />
  </PatientInfoLayout>
</template>

<script>
import {
  format,
  parse,
  add,
  sub,
  getUnixTime,
  fromUnixTime,
  differenceInCalendarMonths,
  getHours,
  getMinutes,
  getSeconds,
} from "date-fns";
import { mapGetters } from "vuex";
import popper from "@/assets/js/popper.js";
import { PrescriptionService } from "@/services/prescription.service";
import PatientInfoLayout from "@/layouts/PatientInfoLayout.vue";
import ModalInAddition from "@/components/prescription/ModalInAddition.vue";
import _ from "lodash";

export default {
  name: "order",
  components: {
    PatientInfoLayout,
    ModalInAddition,
  },
  mixins: [popper],
  data() {
    return {
      isRefill: false,
      isUpdateRefill: false,
      processing: false,
      config: {
        altInput: true,
        altFormat: "m-d-Y",
        allowInput: true,
      },
      productid: "",
      prescriptions: [],
      dosages: [],
      units: [],
      dosageSelected: {},
      frequency: ["Daily", "Weekly", "Monthly"],
      prescription: {
        pricingid: "",
        productid: "",
        dosage: "",
        unit: "",
        notes: "",
        refillinput: "",
      },
      subscriptionstartdate: format(new Date(), "yyyy-MM-dd"),
      options: [
        { value: null, text: "Select Option" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
      ],
      alert: {
        show: false,
        type: "info",
        message: "",
      },
      prescriptionforconfirmation: {},
      prescriptionversion: "",
      followUpOptions: [
        { value: "3 Month", text: "3 Months" },
        { value: "1 Year", text: "1 Year" },
        { value: "Manual", text: "Manual" },
      ],
      selectedFollowUpOption: "3 Month",
      followupDate: "",

      oldPrescription: {
        pricingid: "",
        productid: "",
        dosage: "",
        unit: "",
        notes: "",
        refillinput: "",
      },
      oldSubscriptionstartdate: "",

      inAdditionPrescriptions: [],
      duplicateActivePrescription: [],
      duplicateEntryPrescription: [],
      hasDuplicate: false,

      isScheduled: false,
    };
  },
  watch: {
    selectedFollowUpOption: {
      handler(val) {
        if (val === "3 Month") {
          this.followupDate = format(
            add(new Date(), { days: 3 * 28 - 7 }),
            "yyyy-MM-dd"
          );
        } else if (val === "1 Year") {
          this.followupDate = format(
            add(new Date(), { days: 13 * 28 - 7 }),
            "yyyy-MM-dd"
          );
        } else {
          this.followupDate = format(
            add(new Date(), { days: 1 }),
            "yyyy-MM-dd"
          );
        }
      },
      immediate: true,
    },
  },
  methods: {
    dateDisabled(ymd, date) {
      return new Date() > date;
    },
    subscriptionStartDateDisabled(ymd, date) {
      return sub(new Date(), { days: 1 }) > date;
    },
    alertMessage(type, message) {
      this.alert = {
        type: type,
        message: message,
        show: true,
      };
    },
    selectedPrescription(order) {
      this.productid = order.productid;
      this.prescription.pricingid = "";
      this.prescription.dosage = "";
      this.prescription.notes = "";
    },
    changeDosage(order) {
      let unit = this.units[order.productid].find((o) => {
        return o.dosage === order.dosage;
      });

      // if (!unit) {
      //   // Change unit automatically for DHEA when dosage changes
      //   unit = this.units[order.productid].find((o) => {
      //     return o.dosage === order.dosage;
      //   });
      // }

      this.prescription.pricingid = unit.value;
      this.prescription.unit = unit.text;
      this.prescription.notes = unit.notes;

      if (unit?.defaultrefill || unit?.defaultrefill === 0) {
        this.prescription.refillinput = unit.defaultrefill;
        return;
      }

      if (unit.name === "Blossom") {
        this.prescription.refillinput = 0;
      } else {
        if (unit.subscriptiontype === "Monthly") {
          this.prescription.refillinput = 3;
        } else if (unit.subscriptiontype === "Quarterly") {
          this.prescription.refillinput = 1;
        }
      }
    },
    cancelSendConfirmation() {
      this.$router.back();
      this.processing = false;
    },
    async SendConfirmation() {
      // await PrescriptionService.sendPrescriptionOrderConfirmation(
      // for(let record of this.prescriptionsforconfirmation){
      await PrescriptionService.sendPrescriptionOrderConfirmation(
        this.prescriptionforconfirmation.patientid,
        this.prescriptionforconfirmation.id
      );
      // }
      this.$router.back();
      this.processing = false;
    },
    selectedUnit(productid, pricingid) {
      let unit = this.units[productid].filter((o) => {
        return o.value === pricingid;
      });

      let order = this.prescription;

      if (productid == order.productid) {
        order.unit = unit[0].text;
      }

      let selectedDosage = unit.find((val) => val.dosage === order.dosage);
      if (selectedDosage) {
        order.notes = selectedDosage.notes;

        if (
          selectedDosage?.defaultrefill ||
          selectedDosage?.defaultrefill === 0
        ) {
          order.refillinput = selectedDosage?.defaultrefill;
        } else {
          if (selectedDosage.subscriptiontype === "Monthly") {
            order.refillinput = 3;
          } else if (selectedDosage.subscriptiontype === "Quarterly") {
            order.refillinput = 1;
          }
        }
      }
    },

    async addPrescription() {
      if (!this.checkEmptyFields(this.prescription)) {
        return;
      }
      this.processing = true;

      await PrescriptionService.addMultiplePrescriptionOrderV2(this.patientId, {
        followupstamp: this.patientInfo.followupdate
          ? this.patientInfo.followupdatestamp
          : getUnixTime(
              parse(
                this.getDateStringWithCurrentTime(this.followupDate),
                "yyyy-MM-dd H:m:s",
                new Date()
              )
            ),
        prescription: [
          {
            dosage: this.prescription.dosage,
            notes: this.prescription.notes,
            pricingid: this.prescription.pricingid,
            productid: this.prescription.productid,
            unit: this.prescription.unit,
            refillprescriptionid: this.prescriptionOrderId,
            refillinput: this.prescription.refillinput,
            timestampsubscriptionstartdate: getUnixTime(
              parse(
                this.getDateStringWithCurrentTime(this.subscriptionstartdate),
                "yyyy-MM-dd H:m:s",
                new Date()
              )
            ),
          },
        ],
      })
        .then((data) => {
          this.$router.back();
          this.processing = false;
        })
        .catch((err) => {
          this.processing = false;
          this.alertMessage("warning", err.message);
        });
    },

    async updatePrescription() {
      try {
        if (!this.checkEmptyFields(this.prescription)) {
          return;
        }
        this.processing = true;

        if (this.hasDuplicate) {
          const newPrescription = this.inAdditionPrescriptions.find(
            (prescription) => prescription.hascurrent
          );
          this.prescription.dosage = newPrescription.dosage;
          this.prescription.productid = newPrescription.productid;
          this.prescription.unit = newPrescription.unit;
          this.prescription.refillinput = newPrescription.refills;
        }

        const { data } = await PrescriptionService.updatePrescriptionOrder(
          this.patientId,
          this.prescriptionOrderId,
          {
            dosage: this.prescription.dosage,
            notes: this.prescription.notes,
            pricingid: this.prescription.pricingid,
            productid: this.prescription.productid,
            unit: this.prescription.unit,
            refillinput: this.prescription.refillinput,
            timestampsubscriptionstartdate: getUnixTime(
              parse(
                this.getDateStringWithCurrentTime(this.subscriptionstartdate),
                "yyyy-MM-dd H:m:s",
                new Date()
              )
            ),
            ...(this.hasDuplicate && {
              inaddition: true,
            }),
          }
        );

        if (data.prescriptionstatus === "DRAFTED") {
          this.$router.back();
          this.processing = false;
        } else {
          this.prescriptionforconfirmation = data;
          this.prescriptionforconfirmation.id = this.prescriptionOrderId;
          this.$refs["confirmation-modal"].show();
        }
        this.$bvModal.hide("inaddition");
        this.hasDuplicate = false;
      } catch (err) {
        this.processing = false;
        if (err.message !== "Duplicate prescription") {
          this.alertMessage("warning", err.message);
        } else {
          const duplicateActivePrescription = [];
          const duplicateList = err?.response?.data?.body?.duplicate;
          duplicateList.forEach((prescription) => {
            duplicateActivePrescription.push({
              status: prescription.status,
              date: prescription.date,
              prescription: prescription.medicinename,
              dosage: prescription.dosage,
              unit: prescription.unit,
              refills: prescription.refills,
              productid: prescription.productid,
              ...(prescription.status === "New" &&
                duplicateList.some(
                  (item) =>
                    item.productid === prescription.productid &&
                    item.status === "Current"
                ) && {
                  hascurrent: true,
                }),
            });
          });
          this.inAdditionPrescriptions = duplicateActivePrescription;
          this.hasDuplicate = true;
          this.$bvModal.show("inaddition");
        }
      }
    },
    getDateStringWithCurrentTime(dateString) {
      const currentDate = new Date();
      return `${dateString} ${getHours(currentDate)}:${getMinutes(
        currentDate
      )}:${getSeconds(currentDate)}`;
    },
    async getPrescriptions() {
      const products = await this.$store.dispatch(
        "prescription/getProductDosageAndUnit",
        { patientid: this.patientId }
      );

      this.prescriptions = products.map(
        ({ id: productid, details: dosages, name }) => {
          this.dosages[productid] = [];
          this.units[productid] = [];
          for (let i = 0; i < dosages.length; i++) {
            let dosage = dosages[i];
            this.dosages[productid].push({
              value: dosage.dosage,
              text: dosage.dosagedisplay,
            });

            for (let j = 0; j < dosage.detail.length; j++) {
              let unit = dosage.detail[j];
              this.units[productid].push({
                value: unit.id,
                text: unit.unit,
                subscriptiontype: unit.subscriptiontype,
                notes: unit.notes,
                dosage: dosage.dosage,
                default: dosage.default ? true : false,
                name,
                defaultrefill: unit.defaultrefill,
              });
            }
          }

          return {
            value: productid,
            text: name,
          };
        }
      );
    },
    checkEmptyFields(prescription) {
      let isValid = true;
      const hasNotes = Boolean(prescription.notes);
      if (!hasNotes) {
        this.alertMessage("warning", "Doctor Notes field is required.");
        isValid = false;
      }
      return isValid;
    },
    async cancel() {
      if (this.isRefill) {
        localStorage.removeItem("isRefill");
        this.$router.back();
      } else {
        if (!this.isScheduled) {
          await PrescriptionService.updatePrescriptionOrder(
            this.patientId,
            this.prescriptionOrderId,

            {
              dosage: this.oldPrescription.dosage,
              notes: this.oldPrescription.notes,
              pricingid: this.oldPrescription.pricingid,
              productid: this.oldPrescription.productid,
              unit: this.oldPrescription.unit,
              refillinput: this.oldPrescription.refillinput,
              timestampsubscriptionstartdate: getUnixTime(
                parse(
                  this.getDateStringWithCurrentTime(
                    this.oldSubscriptionstartdate
                  ),
                  "yyyy-MM-dd H:m:s",
                  new Date()
                )
              ),
            }
          );

          localStorage.removeItem("isRefill");
        }
        this.$router.back();
      }
    },
    parseDate(date, stringFormat) {
      return format(fromUnixTime(date), stringFormat);
    },
    pluralize(amount, singular, plural = `${singular}s`) {
      return amount === 1 ? singular : plural;
    },
  },
  computed: {
    monthsUntilFollowUp() {
      return differenceInCalendarMonths(
        new Date(this.patientInfo.followupdate),
        new Date()
      );
    },
    ...mapGetters({
      patientInfo: "patients/getPatientInfo",
      previousRoute: "globals/previousRoute",
    }),
  },
  async mounted() {
    // let isRefill = localStorage.getItem("isRefill")
    // if (isRefill){
    //   // localStorage.removeItem("isRefill")
    //   this.isRefill = true
    // }
    window.analytics.page();
    this.processing = true;
    this.patientId = this.$route.params.id;
    this.prescriptionOrderId = this.$route.params.prescriptionid;
    await this.getPrescriptions();
    await this.$store.dispatch("patients/getPatientInfo", {
      patientId: this.patientId,
    });
    this.$store
      .dispatch("prescription/getPrescriptionOrder", {
        patientId: this.patientId,
        prescriptionOrderId: this.prescriptionOrderId,
      })
      .then((res) => {
        const selectedPricing = this.units[res.productid].find(
          (unit) =>
            unit.subscriptiontype === res.subscriptiontype &&
            unit.dosage === res.dosage
        );
        if (selectedPricing) {
          this.prescription.dosage = selectedPricing.dosage;
          this.prescription.pricingid = selectedPricing.value;
          this.prescription.productid = res.productid;
          this.prescription.unit = selectedPricing.text;
          this.prescription.refillinput = res.refillinput;
          this.prescription.notes = res.notes;
        }

        if (res.prescriptionstatus === "COMPLETED") {
          this.isRefill = true;
          this.selectedFollowUpOption = "1 Year";
        }
        if (res.prescriptionstatus === "SCHEDULED") {
          this.isScheduled = true;
        }
        if (res.refillprescriptionid != undefined) {
          this.isUpdateRefill = true;
        }

        if (res.timestampsubscriptionstartdate) {
          this.subscriptionstartdate = this.parseDate(
            res.timestampsubscriptionstartdate,
            "yyyy-MM-dd"
          );
        } else {
          this.subscriptionstartdate = format(new Date(), "yyyy-MM-dd");
        }

        this.productid = res.productid;
        this.prescriptionversion = res.prescriptionversion;

        this.oldPrescription = { ...this.prescription };
        this.oldSubscriptionstartdate = this.subscriptionstartdate;

        this.processing = false;
      })
      .catch(() => (this.processing = false));
  },
};
</script>

<style scoped>
/deep/.input-group > .input-group-prepend > .input-group-text {
  border-radius: 0;
  border: 0;
  background-color: transparent;
}
</style>

<style lang="scss" scoped>
.prescription-card {
  background-color: $quaternary;
  border-color: $quaternary;
  padding: 1.25rem;
}

.update-order,
.followup-card,
.prescription-details {
  background-color: rgba(26, 106, 114, 0.03);
}
</style>
