var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "inaddition",
        "hide-header": "",
        "hide-header-close": "",
        centered: "",
        "hide-footer": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        size: "lg",
      },
      on: { hide: _vm.onHide },
    },
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _c("div", { staticClass: "icon-logo" }),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tw-float-right tw-w-full lg:tw-w-4/5" },
        [
          _c("div", [
            _c("h2", { staticClass: "mb-3 heading tw-text-2xl" }, [
              _vm._v(
                " The patient already has a prescription or has a duplicate entry for this medication. Do you want this in addition? "
              ),
            ]),
          ]),
          _c(
            "b-table-simple",
            { attrs: { small: "", responsive: "" } },
            [
              _c(
                "b-thead",
                [
                  _c(
                    "b-tr",
                    [
                      _c("b-th", [_vm._v("Status")]),
                      _c("b-th", [_vm._v("Date")]),
                      _c("b-th", [_vm._v("Prescription")]),
                      _c("b-th", [_vm._v("Dosage")]),
                      _c("b-th", [_vm._v("Unit")]),
                      _c("b-th", [_vm._v("Refills")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-tbody",
                _vm._l(_vm.prescriptions, function (prescription, i) {
                  return _c(
                    "b-tr",
                    {
                      key: i,
                      class: {
                        "tw-text-primary": prescription.status === "New",
                      },
                    },
                    [
                      _c("b-th", [_vm._v(_vm._s(prescription.status))]),
                      _c("b-th", [
                        _vm._v(
                          _vm._s(_vm.parseDate(prescription.date, "MM/dd/yy"))
                        ),
                      ]),
                      _c("b-td", [_vm._v(_vm._s(prescription.prescription))]),
                      _c("b-td", [_vm._v(_vm._s(prescription.dosage))]),
                      _c("b-td", [_vm._v(_vm._s(prescription.unit))]),
                      _c("b-td", [_vm._v(_vm._s(prescription.refills))]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mb-4" },
            [
              _c(
                "b-col",
                { staticClass: "mb-2", attrs: { cols: "8" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        block: "",
                        pill: "",
                        variant: "primary",
                        disabled: _vm.processing,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("in-addition")
                        },
                      },
                    },
                    [
                      _vm.processing
                        ? _c("b-spinner", { attrs: { small: "" } })
                        : _c("span", [_vm._v("Yes")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "8" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        block: "",
                        pill: "",
                        variant: "outline-primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$bvModal.hide("inaddition")
                        },
                      },
                    },
                    [_c("span", [_vm._v("No")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }