var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PatientInfoLayout",
    [
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "div",
            {
              staticClass:
                "tw-top-0 tw-py-5 lg:tw-flex tw-justify-between tw-items-center tw-flex-none tw-px-4",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "tw-flex-initial tw-flex tw-items-center tw-text-warning",
                },
                [
                  _c("span", {
                    staticClass: "back-arrow tw-mr-3 tw-cursor-pointer",
                    on: { click: _vm.cancel },
                  }),
                  _vm.previousRoute
                    ? _c("span", { staticClass: "tw-text-warning" }, [
                        _vm._v(
                          " " + _vm._s(_vm.previousRoute.meta.title) + " "
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "div",
                { staticClass: "lg:tw-pr-6" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "tw-text-primary tw-underline",
                      attrs: { size: "sm", variant: "link", pill: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push(
                            `/patients/${_vm.patientId}/intake`
                          )
                        },
                      },
                    },
                    [_vm._v(" View Intake History ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-row",
            {
              staticClass: "tw-px-4",
              staticStyle: { "padding-top": "20px", "padding-bottom": "20px" },
              attrs: { "align-h": "center", "align-v": "center" },
            },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("h4", { staticClass: "mr-4 text-primary" }, [
                            _vm._v("Patient Information"),
                          ]),
                          !_vm.patientInfo.patientno
                            ? _c(
                                "b-card",
                                {
                                  staticClass: "prescription-card",
                                  attrs: { "no-body": "" },
                                },
                                [
                                  _c(
                                    "b-row",
                                    { attrs: { "align-v": "center" } },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "9" } },
                                        [
                                          _c(
                                            "b-row",
                                            { attrs: { "align-v": "center" } },
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass: "mb-3",
                                                  attrs: { cols: "auto" },
                                                },
                                                [
                                                  _c("b-skeleton", {
                                                    attrs: {
                                                      animation: "fade",
                                                      type: "avatar",
                                                      size: "72px",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                [
                                                  _c("b-skeleton", {
                                                    attrs: {
                                                      animation: "fade",
                                                      width: "45%",
                                                    },
                                                  }),
                                                  _c("b-skeleton", {
                                                    attrs: {
                                                      animation: "fade",
                                                      width: "55%",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "text-right",
                                          attrs: { cols: "12", md: "3" },
                                        },
                                        [
                                          _c("b-skeleton", {
                                            attrs: {
                                              animation: "fade",
                                              width: "85%",
                                            },
                                          }),
                                          _c("b-skeleton", {
                                            attrs: {
                                              animation: "fade",
                                              width: "65%",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "b-card",
                                {
                                  staticClass: "prescription-card",
                                  attrs: { "no-body": "" },
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "b-row",
                                            { attrs: { "align-v": "center" } },
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    lg: "9",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-row",
                                                    {
                                                      attrs: {
                                                        "align-v": "center",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "b-col",
                                                        {
                                                          staticClass: "mb-3",
                                                          attrs: {
                                                            cols: "12",
                                                            lg: "2",
                                                          },
                                                        },
                                                        [
                                                          _c("b-avatar", {
                                                            attrs: {
                                                              size: "72px",
                                                              src: _vm
                                                                .patientInfo
                                                                .profileUrl,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            lg: "10",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "h3",
                                                            {
                                                              staticClass:
                                                                "mb-1 bold",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    `${_vm.patientInfo.firstname} ${_vm.patientInfo.lastname}`
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _vm.patientInfo
                                                                .transferredfromdoctorname
                                                                ? _c("small", [
                                                                    _vm._v(
                                                                      " (Transferred from Dr. " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .patientInfo
                                                                            .transferredfromdoctorname
                                                                        ) +
                                                                        ") "
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                          _vm.patientInfo
                                                            .desiredplan
                                                            ? [
                                                                _c(
                                                                  "b-row",
                                                                  {
                                                                    staticClass:
                                                                      "py-1",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          lg: "4",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Requested Medication: "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        staticClass:
                                                                          "text-black",
                                                                      },
                                                                      _vm._l(
                                                                        _vm
                                                                          .patientInfo
                                                                          .desiredplan,
                                                                        function (
                                                                          plan,
                                                                          i
                                                                        ) {
                                                                          return _c(
                                                                            "span",
                                                                            {
                                                                              key: i,
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    plan.name +
                                                                                      (plan.pricingdetail !=
                                                                                      undefined
                                                                                        ? " - " +
                                                                                          plan
                                                                                            .pricingdetail
                                                                                            .nickname
                                                                                        : (i ===
                                                                                          _vm
                                                                                            .patientInfo
                                                                                            .desiredplan
                                                                                            .length -
                                                                                            1
                                                                                            ? ""
                                                                                            : ",") ||
                                                                                          "")
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                              plan.pricingdetail !=
                                                                              undefined
                                                                                ? _c(
                                                                                    "br"
                                                                                  )
                                                                                : _vm._e(),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                      0
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            : _vm.patientInfo
                                                                .preferredtreatment
                                                            ? [
                                                                _c(
                                                                  "b-row",
                                                                  {
                                                                    staticClass:
                                                                      "py-1",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          lg: "4",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Requested Medication: "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        staticClass:
                                                                          "text-black",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .patientInfo
                                                                                .preferredtreatment ||
                                                                                ""
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            : [
                                                                _c(
                                                                  "b-row",
                                                                  {
                                                                    staticClass:
                                                                      "py-1",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          lg: "4",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Requested Medication: "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        staticClass:
                                                                          "text-black",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Let doctor choose medication "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                          _vm.patientInfo
                                                            .preferredshipmentname
                                                            ? [
                                                                _c(
                                                                  "b-row",
                                                                  {
                                                                    staticClass:
                                                                      "py-1",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          lg: "4",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Preferred Shipment: "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        staticClass:
                                                                          "text-black",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .patientInfo
                                                                                    .preferredshipmentname ||
                                                                                    ""
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            : _vm._e(),
                                                          _vm.patientInfo
                                                            .unselectedproduct !=
                                                            undefined &&
                                                          _vm.patientInfo
                                                            .unselectedproduct
                                                            .length > 0
                                                            ? [
                                                                _c(
                                                                  "b-row",
                                                                  {
                                                                    staticClass:
                                                                      "py-1",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          lg: "4",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Declined onboarding treatment: "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        staticClass:
                                                                          "text-black",
                                                                      },
                                                                      _vm._l(
                                                                        _vm
                                                                          .patientInfo
                                                                          .unselectedproduct,
                                                                        function (
                                                                          plan,
                                                                          i
                                                                        ) {
                                                                          return _c(
                                                                            "span",
                                                                            {
                                                                              key: i,
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    plan.name +
                                                                                      (i ===
                                                                                      _vm
                                                                                        .patientInfo
                                                                                        .unselectedproduct
                                                                                        .length -
                                                                                        1
                                                                                        ? ""
                                                                                        : ",") ||
                                                                                      ""
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                      0
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            : _vm._e(),
                                                        ],
                                                        2
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass: "text-right",
                                                  attrs: {
                                                    cols: "12",
                                                    lg: "3",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "h3",
                                                    {
                                                      staticClass: "mb-0 bold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.patientInfo
                                                              .patientno
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "a",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-modal",
                                                          rawName:
                                                            "v-b-modal.intake",
                                                          modifiers: {
                                                            intake: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "text-underline",
                                                      attrs: { href: "#" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " View Intake History "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.prescriptions.length > 0 && _vm.isRefill
                    ? _c(
                        "b-row",
                        { attrs: { "align-h": "center" } },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12", md: "10" } },
                            [
                              _c("h4", { staticClass: "mr-4 text-primary" }, [
                                _vm._v("Follow-up"),
                              ]),
                              _c(
                                "b-card",
                                {
                                  staticClass: "followup-card",
                                  staticStyle: { position: "relative" },
                                },
                                [
                                  _vm.patientInfo.followupdate
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "tw-mb-4 lg:tw-mb-0",
                                              attrs: { cols: "12", md: "3" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "mb-1 text-primary",
                                                },
                                                [
                                                  _c("small", [
                                                    _vm._v(
                                                      "Current Follow up Date"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mb-0 text-default",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.parseDate(
                                                          _vm.patientInfo
                                                            .followupdatestamp,
                                                          "MMMM d, yyyy"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12", md: "3" } },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "mb-1 text-primary",
                                                },
                                                [
                                                  _c("small", [
                                                    _vm._v(
                                                      "Months Until Follow Up"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mb-0 text-default",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.monthsUntilFollowUp
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.pluralize(
                                                          _vm.monthsUntilFollowUp,
                                                          "Month"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "b-row",
                                        { staticClass: "mb-4" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c(
                                                "div",
                                                { attrs: { role: "group" } },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-primary",
                                                      attrs: {
                                                        for: "followup",
                                                      },
                                                    },
                                                    [
                                                      _c("small", [
                                                        _vm._v(
                                                          "Schedule Follow-up"
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c("v-select", {
                                                    attrs: {
                                                      id: "followup",
                                                      "append-to-body": "",
                                                      "calculate-position":
                                                        _vm.withPopper,
                                                      label: "text",
                                                      options:
                                                        _vm.followUpOptions,
                                                      reduce: (
                                                        followUpOptions
                                                      ) =>
                                                        followUpOptions.value,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "open-indicator",
                                                          fn: function ({
                                                            attributes,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "b-icon",
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      icon: "chevron-down",
                                                                      variant:
                                                                        "primary",
                                                                    },
                                                                  },
                                                                  "b-icon",
                                                                  attributes,
                                                                  false
                                                                )
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      709101353
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.selectedFollowUpOption,
                                                      callback: function ($$v) {
                                                        _vm.selectedFollowUpOption =
                                                          $$v
                                                      },
                                                      expression:
                                                        "selectedFollowUpOption",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "text-primary",
                                                  attrs: {
                                                    for: "followupdate",
                                                  },
                                                },
                                                [
                                                  _c("small", [
                                                    _vm._v("Follow-up Date"),
                                                  ]),
                                                ]
                                              ),
                                              _c("b-form-datepicker", {
                                                attrs: {
                                                  id: "followupdate",
                                                  disabled:
                                                    _vm.selectedFollowUpOption !==
                                                    "Manual",
                                                  "date-disabled-fn":
                                                    _vm.dateDisabled,
                                                },
                                                model: {
                                                  value: _vm.followupDate,
                                                  callback: function ($$v) {
                                                    _vm.followupDate = $$v
                                                  },
                                                  expression: "followupDate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.prescriptions.length > 0
                    ? _c(
                        "b-row",
                        { attrs: { "align-h": "center" } },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("h4", { staticClass: "mr-4 text-primary" }, [
                                _vm._v("Prescription Order"),
                              ]),
                              _c(
                                "b-alert",
                                {
                                  attrs: {
                                    show: _vm.alert.show,
                                    variant: _vm.alert.type,
                                  },
                                },
                                [_vm._v(_vm._s(_vm.alert.message))]
                              ),
                              _c(
                                "b-card",
                                {
                                  staticClass: "prescription-details",
                                  staticStyle: { position: "relative" },
                                },
                                [
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-4" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "text-primary",
                                              attrs: {
                                                for: "subscription_startdate",
                                              },
                                            },
                                            [
                                              _c("small", [
                                                _vm._v(
                                                  "Subscription Start Date"
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c("b-form-datepicker", {
                                            attrs: {
                                              id: "subscription_startdate",
                                              "date-disabled-fn":
                                                _vm.subscriptionStartDateDisabled,
                                            },
                                            model: {
                                              value: _vm.subscriptionstartdate,
                                              callback: function ($$v) {
                                                _vm.subscriptionstartdate = $$v
                                              },
                                              expression:
                                                "subscriptionstartdate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-card",
                                { staticClass: "update-order" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "tw-grid tw-grid-cols-10 tw-mb-4 tw-space-y-4 lg:tw-space-y-0 md:tw-space-x-4 tw-place-items-stretch",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tw-justify-between tw-flex tw-flex-col tw-col-span-10 md:tw-col-span-4",
                                          attrs: { role: "group" },
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "text-primary",
                                              attrs: { for: "prescription" },
                                            },
                                            [
                                              _c("small", [
                                                _vm._v("Prescription"),
                                              ]),
                                            ]
                                          ),
                                          _c("v-select", {
                                            staticClass: "prescription-select",
                                            attrs: {
                                              id: "prescription",
                                              "append-to-body": "",
                                              "calculate-position":
                                                _vm.withPopper,
                                              label: "text",
                                              options: _vm.prescriptions,
                                              reduce: (prescriptions) =>
                                                prescriptions.value,
                                              disabled:
                                                !_vm.prescription.productid ||
                                                _vm.isUpdateRefill === true,
                                              placeholder: "Fetching data...",
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.selectedPrescription(
                                                  _vm.prescription
                                                )
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "open-indicator",
                                                  fn: function ({
                                                    attributes,
                                                  }) {
                                                    return [
                                                      _c(
                                                        "b-icon",
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              icon: "chevron-down",
                                                              variant:
                                                                "primary",
                                                            },
                                                          },
                                                          "b-icon",
                                                          attributes,
                                                          false
                                                        )
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              709101353
                                            ),
                                            model: {
                                              value: _vm.prescription.productid,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.prescription,
                                                  "productid",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "prescription.productid",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tw-justify-between tw-flex tw-flex-col tw-col-span-10 md:tw-col-span-2",
                                          attrs: { role: "group" },
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "text-primary",
                                              attrs: { for: "dosage" },
                                            },
                                            [_c("small", [_vm._v("Dosage")])]
                                          ),
                                          _c("v-select", {
                                            staticClass: "prescription-select",
                                            attrs: {
                                              id: "dosage",
                                              "append-to-body": "",
                                              "calculate-position":
                                                _vm.withPopper,
                                              label: "text",
                                              options:
                                                _vm.dosages[_vm.productid] &&
                                                _vm.dosages[
                                                  _vm.productid
                                                ].filter(
                                                  ({ value }) => value !== ""
                                                ),
                                              reduce: (dosages) =>
                                                dosages.value,
                                              disabled:
                                                !_vm.prescription.productid,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.changeDosage(
                                                  _vm.prescription
                                                )
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "open-indicator",
                                                  fn: function ({
                                                    attributes,
                                                  }) {
                                                    return [
                                                      _c(
                                                        "b-icon",
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              icon: "chevron-down",
                                                              variant:
                                                                "primary",
                                                            },
                                                          },
                                                          "b-icon",
                                                          attributes,
                                                          false
                                                        )
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              709101353
                                            ),
                                            model: {
                                              value: _vm.prescription.dosage,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.prescription,
                                                  "dosage",
                                                  $$v
                                                )
                                              },
                                              expression: "prescription.dosage",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tw-justify-between tw-flex tw-flex-col tw-col-span-10 md:tw-col-span-3",
                                          attrs: { role: "group" },
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "text-primary",
                                              attrs: { for: "unit" },
                                            },
                                            [_c("small", [_vm._v("Unit")])]
                                          ),
                                          _c("v-select", {
                                            staticClass: "prescription-select",
                                            attrs: {
                                              id: "unit",
                                              "append-to-body": "",
                                              "calculate-position":
                                                _vm.withPopper,
                                              label: "text",
                                              options:
                                                _vm.units[_vm.productid] &&
                                                _vm.units[_vm.productid].filter(
                                                  (unit) =>
                                                    unit.dosage ===
                                                    _vm.prescription.dosage
                                                ),
                                              reduce: (units) => units.value,
                                              clearable: "",
                                              disabled:
                                                !_vm.prescription.dosage,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.selectedUnit(
                                                  _vm.productid,
                                                  _vm.prescription.pricingid
                                                )
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "open-indicator",
                                                  fn: function ({
                                                    attributes,
                                                  }) {
                                                    return [
                                                      _c(
                                                        "b-icon",
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              icon: "chevron-down",
                                                              variant:
                                                                "primary",
                                                            },
                                                          },
                                                          "b-icon",
                                                          attributes,
                                                          false
                                                        )
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              709101353
                                            ),
                                            model: {
                                              value: _vm.prescription.pricingid,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.prescription,
                                                  "pricingid",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "prescription.pricingid",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tw-justify-between tw-flex tw-flex-col tw-col-span-10 md:tw-col-span-1",
                                          attrs: { role: "group" },
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "text-primary",
                                              attrs: { for: "unit" },
                                            },
                                            [_c("small", [_vm._v("Refill")])]
                                          ),
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "refill",
                                              type: "number",
                                            },
                                            model: {
                                              value:
                                                _vm.prescription.refillinput,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.prescription,
                                                  "refillinput",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "prescription.refillinput",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", { attrs: { cols: "12" } }, [
                                        _c(
                                          "div",
                                          { attrs: { role: "group" } },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "text-primary",
                                                attrs: { for: "textarea" },
                                              },
                                              [
                                                _c("small", [
                                                  _vm._v("Doctor Notes"),
                                                ]),
                                              ]
                                            ),
                                            _c("b-form-textarea", {
                                              staticClass: "pl-0",
                                              staticStyle: { overflow: "auto" },
                                              attrs: {
                                                id: "textarea",
                                                rows: "3",
                                                "max-rows": "6",
                                              },
                                              model: {
                                                value: _vm.prescription.notes,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.prescription,
                                                    "notes",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "prescription.notes",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                { staticClass: "tw-space-y-4 lg:tw-space-y-0" },
                                [
                                  _c("b-col", {
                                    attrs: { cols: "12", md: "6" },
                                  }),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            pill: "",
                                            variant: "outline-primary",
                                            block: "",
                                            disabled: _vm.processing,
                                          },
                                          on: { click: _vm.cancel },
                                        },
                                        [_vm._v("Cancel")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _vm.isRefill === true
                                        ? _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                pill: "",
                                                variant: "primary",
                                                block: "",
                                                disabled: _vm.processing,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.addPrescription.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.processing
                                                    ? "Processing"
                                                    : "Save"
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.isRefill === false
                                        ? _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                pill: "",
                                                variant: "primary",
                                                block: "",
                                                disabled: _vm.processing,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.updatePrescription.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.processing
                                                    ? "Processing"
                                                    : "Save"
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "b-card",
                                {
                                  staticStyle: {
                                    "background-color":
                                      "rgba(247, 247, 247, 0.5)",
                                  },
                                },
                                [
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-4" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c("b-skeleton", {
                                            attrs: {
                                              animation: "fade",
                                              type: "input",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "3" } },
                                        [
                                          _c("b-skeleton", {
                                            attrs: {
                                              animation: "fade",
                                              type: "input",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "3" } },
                                        [
                                          _c("b-skeleton", {
                                            attrs: {
                                              animation: "fade",
                                              type: "input",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-4" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("b-skeleton", {
                                            attrs: {
                                              animation: "fade",
                                              type: "input",
                                              height: "150px",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "4", md: "6" } },
                                    [
                                      _c("b-skeleton", {
                                        attrs: {
                                          type: "button",
                                          width: "150px",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "4", md: "3" } },
                                    [
                                      _c("b-skeleton", {
                                        attrs: {
                                          type: "button",
                                          width: "100%",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "4", md: "3" } },
                                    [
                                      _c("b-skeleton", {
                                        attrs: {
                                          type: "button",
                                          width: "100%",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("ModalInAddition", {
        attrs: {
          prescriptions: _vm.inAdditionPrescriptions,
          processing: _vm.processing,
        },
        on: {
          "in-addition": _vm.updatePrescription,
          "modal-close": function ($event) {
            _vm.hasDuplicate = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }